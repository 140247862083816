<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 活动列表中的数据与我的商品中数据不一样</span>
  </div>
  <div class="text">
    <h3>活动列表中的数据与我的商品中数据不一样</h3>
    <p>
      活动页面的数据主要来自联盟页面数据，预估效果和绩效管理中的数据都是根据下载的订单计算得到，订单20分钟获取一次，联盟数据每日更新一次，因为更新周期不一致所以会出现数据不一样的情况；
    </p>
  </div>
</template>