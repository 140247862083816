<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 员工子账号密码设置说明</span>
  </div>
  <div class="text">
    <h3>员工子账号密码设置说明</h3>
    <p>
      招商系统注册用户或团长在添加成员（子账号）时账号和密码只可设置为数字或字母6-16位，注册用户的注册手机号即为账号；
    </p>
    <img
      src="../../assets/help/addperson2.png"
      alt=""
      style="width: 800px; cursor: pointer"
      @click="enlarge"
    />
  </div>
  <div class="mask" id="mask" @click="enlarge2">
    <img src="../../assets/help/addperson2.png" alt="" style="width: 80%" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import "./my-style.scss";

export default defineComponent({
  setup() {
    const enlarge = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block";
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none";
    };
    return {
      enlarge,
      enlarge2,
    };
  },
});
</script>

