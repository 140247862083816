<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 密码忘记了该怎么办</span>
  </div>
  <div class="text">
    <h3>密码忘记了该怎么办</h3>
    <p>
      注册账号密码忘记后可在锵行招商系统登录页面点击忘记密码，根据手机验证码重置密码后即可找回密码，子账号忘
      记密码后可联系开子账号人员在
      员工管理菜单中找到相应账号点击重置密码即可将密码重置为123456；
    </p>
    <img
      src="../../assets/help/password.png"
      alt=""
      style="width: 800px; cursor: pointer;"
      @click="enlarge"
    />
  </div>
  <div class="mask" id="mask" @click="enlarge2">
    <img src="../../assets/help/password.png" alt="" style="width: 80%" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import "./my-style.scss";

export default defineComponent({
  setup() {
    const enlarge = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block"
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none"
    };
    return {
      enlarge,
      enlarge2
    };
  },
});
</script>

