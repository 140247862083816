<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 怎么看预估业绩和结算业绩</span>
  </div>
  <div class="text">
    <h3>怎么看预估业绩和结算业绩</h3>
    <p>
      在锵行招商系统【业绩统计】页面里可以同时查看结算业绩和预估业绩，可根据时间查询想看的业绩情况，预估业绩和结算业绩一目了然，昨日、今
      日、周和月业绩按时间查询即可；
    </p>
    <img src="../../assets/help/achievement4.png" alt="" style="width: 837px; cursor: pointer;"
      @click="enlarge" />
  </div>
   <div class="mask" id="mask" @click="enlarge2">
    <img src="../../assets/help/achievement4.png" alt="" style="width: 80%" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    const enlarge = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block"
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none"
    };
    return {
      enlarge,
      enlarge2
    };
  },
});
</script>

