<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 招商插件浏览器安装方法</span>
  </div>
  <div class="text">
    <h3>招商插件浏览器安装方法</h3>
    <br />
    <span
      >提示：请选择您对应的浏览器下载，如果插件被浏览器禁用、无法启动，请根据对应浏览器教程重新安装即可</span
    >
    <h4>直接拖动安装（适用于大多数浏览器）</h4>
    <p>该方法适用于如下多种浏览器：</p>
    <ul>
      <li>1.360安全浏览器</li>
      <li>2.360急速浏览器</li>
      <li>3.2345加速浏览器</li>
      <li>4.UC浏览器</li>
    </ul>
    <h5>第一步：下载插件</h5>
    <p>
      在锵行招商系统管网招商插件页面下载最新安装版本文件：http://www.tuipinjiang.com/#/cj
    </p>
    <ul>
      <li>1.选择对应的浏览器版本，点击下载</li>
      <li>2.确认下载成功，文件类型为：crx后缀结尾</li>
    </ul>
    <h5>第二步：拖动安装文件到浏览器</h5>
    <p>拖动crx文件到浏览器即可</p>
    <h4>Chrome和QQ浏览器安装方法</h4>
    <p>该方法适用于Chrome和QQ浏览器</p>
    <h5>第一步：下载插件</h5>
    <p>在锵行招商系统管网招商插件页面下载最新安装版本文件：http://www.tuipinjiang.com/#/cj</p>
    <ul>
        <li>1.选择对应的浏览器版本，点击下载</li>
        <li>2.确认下载成功，文件类型为：zip后缀结尾</li>
    </ul>
    <h5>第二步：复制chrome://extensions到浏览器地址栏打开</h5>
    <img src="../../assets/help/psp.png" alt="" style="width: 800px; cursor: pointer;"
      @click="enlarge" />
    <h5>第三步：解压下载的文件，拖动文件夹到浏览器</h5>
    <p>拖动文件夹到浏览器窗口即可</p>
  </div>
    <div class="mask" id="mask" @click="enlarge2">
    <img src="../../assets/help/psp.png" alt="" style="width: 80%;margin:4% 0" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  setup() {
    const enlarge = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block"
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none"
    };
    return {
      enlarge,
      enlarge2
    };
  },
});
</script>
<style scoped lang="scss">
.text {
  padding: 50px 50px;
  h3 {
    font-size: 38px;
    font-weight: 400;
    line-height: 38px;
    color: #3e3e3e;
  }
  h4{
    color: #333;
    margin: 10px 0;
    font-weight: normal;
  }
  h5{
    color: #333;
    margin: 15px 0;
    font-weight: normal;
  }
  ul{
    list-style: none;
    color: #333;
    font-size: 12px;
  }
  p {
    margin:10px 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #3e3e3e;
    width: 837px;
  }
  span {
    color: rgb(249, 150, 59);
    margin:0;
  }
}
</style>