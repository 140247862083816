<template>
  <div class="box">
    <div class="home" ref="bigbox">
      <div class="wzs-banner">
        <img src="../assets/help/text.png" alt="" class="img" />
        <!-- <div class="search">
          <div class="search-box">
            <input type="text" placeholder="请输入关键词" />
            <img src="../assets/help/search.png" alt="" />
          </div>
        </div> -->
      </div>
      <div class="info">
        <div class="info-box">
          <div class="left">
            <div class="question">
              <div class="question-title">
                <img src="../assets/help/icon02.png" alt="" />
                常见问题
              </div>
              <div class="question-nr">
                <div class="question-item" @click="show('show0')">
                  使用问题
                  <img :src="list.srcshow0" alt="" />
                </div>
                <ul class="question-list" v-if="list.show0">
                  <li
                    v-for="(item, index) in list.itemlist.slice(0, 6)"
                    :key="index"
                    :class="item.on ? 'active' : ''"
                    @click="dianji(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="question">
              <div class="question-title">
                <img src="../assets/help/icon01.png" alt="" />
                功能介绍
              </div>
              <div class="question-nr">
                <div class="question-item" @click="show('show1')">
                  系统介绍
                  <img :src="list.srcshow1" alt="" />
                </div>
                <ul class="question-list" v-if="list.show1">
                  <li
                    v-for="(item, index) in list.itemlist.slice(8, 10)"
                    :key="index"
                    :class="item.on ? 'active' : ''"
                    @click="dianji(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </div>
              <div class="question-nr">
                <div class="question-item" @click="show('show2')">
                  基础设置
                  <img :src="list.srcshow2" alt="" />
                </div>
                <ul class="question-list" v-if="list.show2">
                  <li
                    v-for="(item, index) in list.itemlist.slice(10, 11)"
                    :key="index"
                    :class="item.on ? 'active' : ''"
                    @click="dianji(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </div>
              <!-- <div class="question-nr">
                <div class="question-item" @click="show('show3')">
                  菜单功能
                  <img :src="list.srcshow3" alt="" />
                </div>
                <ul class="question-list" v-if="list.show3">
                  <li
                    v-for="(item, index) in list.itemlist.slice(13, 21)"
                    :key="index"
                    :class="item.on ? 'active' : ''"
                    @click="dianji(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </div> -->
              <div class="question-nr">
                <div class="question-item" @click="show('show4')">
                  系统使用说明
                  <img :src="list.srcshow4" alt="" />
                </div>
                <ul class="question-list" v-if="list.show4">
                  <li
                    v-for="(item, index) in list.itemlist.slice(25, 26)"
                    :key="index"
                    :class="item.on ? 'active' : ''"
                    @click="dianji(item)"
                  >
                    {{ item.title }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="right">
            <!-- <div class="head">
              <span>{{ title }} > {{ title1 }} > {{ title2 }}</span>
            </div> -->
            <password v-if="num == 0" />
            <list1 v-if="num == 1" />
            <goods2 v-if="num == 2" />
            <goodserror3 v-if="num == 3" />
            <achievement4 v-if="num == 4" />
            <listunlike5 v-if="num == 5" />
            <psp6 v-if="num == 6" />
            <cookie7 v-if="num == 7" />
            <basic v-if="num == 10" />
            <System v-if="num == 25" />
            <introduction v-if="num == 8 || 9" :num="num" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import password from "@/components/help/password0.vue";
import list1 from "@/components/help/list1.vue";
import goods2 from "@/components/help/goods2.vue";
import goodserror3 from "@/components/help/goodserror3.vue";
import achievement4 from "@/components/help/achievement4.vue";
import listunlike5 from "@/components/help/listunlike5.vue";
import psp6 from "@/components/help/psp6.vue";
import cookie7 from "@/components/help/cookie7.vue";
import introduction from "@/components/help/introduction.vue";
import basic from "@/components/help/basic1.vue";
import System from "@/components/help/System.vue";

export default defineComponent({
  components: {
    password,
    list1,
    goods2,
    goodserror3,
    achievement4,
    listunlike5,
    psp6,
    cookie7,
    introduction,
    basic,
    System
  },
  setup() {
    const state = reactive({
      num: 0,
      list: {
        show0: true,
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        srcshow0: require("../assets/help/down.png"),
        srcshow1: require("../assets/help/up.png"),
        srcshow2: require("../assets/help/up.png"),
        srcshow3: require("../assets/help/up.png"),
        srcshow4: require("../assets/help/up.png"),
        itemlist: [
          {
            id: 0,
            title: "密码忘记了该怎么办",
            on: true,
          },
          {
            id: 1,
            title: "团员活动列表中为什么没有活动",
            on: false,
          },
          {
            id: 2,
            title: "怎么审核活动中的商品",
            on: false,
          },
          {
            id: 3,
            title: "商品认领错误怎么办",
            on: false,
          },
          {
            id: 4,
            title: "怎么看预估业绩和结算业绩",
            on: false,
          },
          {
            id: 5,
            title: "活动列表中的数据与我的商品中数据不一样",
            on: false,
          },
          {
            id: 6,
            title: "招商插件浏览器安装方法",
            on: false,
          },
          {
            id: 7,
            title: "cookie失效解决方法",
            on: false,
          },
          {
            id: 8,
            title: "招商系统角色及功能",
            on: false,
          },
          {
            id: 9,
            title: "操作流程",
            on: false,
          },
          {
            id: 10,
            title: "京东联盟账号绑定",
            on: false,
          },
          {
            id: 11,
            title: "添加子账号",
            on: false,
          },
          {
            id: 12,
            title: "京东联盟设置活动发布条数",
            on: false,
          },
          {
            id: 13,
            title: "京东联盟团长管理",
            on: false,
          },
          {
            id: 14,
            title: "锵行京东招商助手使用方法",
            on: false,
          },
          {
            id: 15,
            title: "京东联盟渠道管理",
            on: false,
          },
          {
            id: 16,
            title: "员工管理",
            on: false,
          },
          {
            id: 17,
            title: "京东联盟绩效数据看板",
            on: false,
          },
          {
            id: 18,
            title: "绩效管理",
            on: false,
          },
          {
            id: 19,
            title: "团队管理",
            on: false,
          },
          {
            id: 20,
            title: "京东辅助工具",
            on: false,
          },
          {
            id: 21,
            title: "线下招商",
            on: false,
          },
          {
            id: 22,
            title: "数据分析",
            on: false,
          },
          {
            id: 23,
            title: "知识库",
            on: false,
          },
          {
            id: 24,
            title: "平台推广操作使用说明",
            on: false,
          },
          {
            id: 25,
            title: "员工子账号密码设置说明",
            on: false,
          },
          {
            id: 26,
            title: "绑定联盟账号使用说明",
            on: false,
          },
          {
            id: 27,
            title: "招商活动添加对接人说明",
            on: false,
          },
          {
            id: 28,
            title: "关于业绩对不上的说明",
            on: false,
          },
        ],
      },
    });

    const show = (show) => {
      let kg = !state.list[show];
      state.list[show] = kg;
      if (kg == false) {
        state.list["src" + show] = require("../assets/help/up.png");
      } else {
        state.list["src" + show] = require("../assets/help/down.png");
      }
    };
    const dianji = (item) => {
      console.log(item);
      state.num = item.id;
      for (let i = 0; i < state.list.itemlist.length; i++) {
        if (item.title == state.list.itemlist[i].title) {
          state.list.itemlist[i].on = true;
        } else {
          state.list.itemlist[i].on = false;
        }
      }
    };
    return {
      show,
      ...toRefs(state),
      dianji,
    };
  },
});
</script>


<style scoped lang="scss">
.box {
  min-height: 1500px;
  .home {
    position: relative;
    text-align: center;
    .wzs-banner {
      width: 100%;
      min-width: 1320px;
      height: 662px;
      background-image: url("../assets/help/banner.png");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      .img {
        margin-top: 180px;
      }
      .search {
        width: 100%;
        min-width: 1300px;
        margin: 0 auto;
        margin-top: 40px;
        .search-box {
          width: 598px;
          position: relative;
          margin: 0 auto;
          input {
            width: 552px;
            height: 55px;
            background-color: rgb(47 102 173 / 0);
            border: 1px solid #ffffff;
            font-size: 30px;
            color: #fff;
            text-indent: 20px;
            outline: none;
          }
          input::placeholder {
            color: #fff;
          }
          img {
            width: 123px;
            position: absolute;
            right: 0;
            top: -6px;
          }
        }
      }
    }
    .info {
      position: relative;
      left: 0;
      top: -160px;
      z-index: 9;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;

      .info-box {
        width: 1300px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: 0 0 24px 0 rgb(47 102 173 / 18%);
        box-sizing: border-box;
        text-align: left;
        display: flex;
        .left {
          // border: 1px solid red;
          width: 361px;
          min-height: 700px;
          background: rgba(52, 125, 192, 1);
          .question {
            color: #ffffff;
            .question-title {
              font-size: 22px;
              font-weight: 400;
              line-height: 18px;
              padding: 46px 0 0 19px;
              img {
                vertical-align: middle;
              }
            }
            .question-nr {
              .question-item {
                height: 62px;
                font-size: 18px;
                font-weight: 400;
                line-height: 18px;
                padding: 14px 8px 13px 60px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
              }
              .question-list {
                list-style: none;
                li {
                  // width: 265px;
                  padding-left: 80px;
                  height: 52px;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 18px;
                  opacity: 0.6;
                  line-height: 52px;
                  cursor: pointer;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .active {
                  opacity: 1;
                  background: rgba(39, 96, 193, 1);
                }
              }
            }
          }
        }
        .right {
          border: 1px solid green;
          flex-grow: 1;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>