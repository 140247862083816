<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 怎么审核活动中的商品</span>
  </div>
  <div class="text">
    <h3>怎么审核活动中的商品</h3>
    <p>
      登录锵行招商系统，在团长管理下的【活动列表】中点击活动标题可以进入报名商品，商家报名的商品专员可点击通过并认领或拒绝商品
    </p>
    <img src="../../assets/help/goods01.png" alt="" style="width: 837px;cursor: pointer;" @click="enlarge(require('../../assets/help/goods01.png'))" />
    <p>如果商品多的话可以勾选商品点击右上角进行批量操作；</p>
    <img src="../../assets/help/goods02.png" alt="" style="width: 837px;cursor: pointer;" @click="enlarge(require('../../assets/help/goods02.png'))" />
  </div>

  <div class="mask" id="mask" @click="enlarge2">
    <img :src="src" alt="" style="width: 80%" />
  </div>
</template>
<script>
import { defineComponent, toRefs, reactive } from "vue";

export default defineComponent({
  setup() {
    const state = reactive({
      src: "",
    });
    const enlarge = (src) => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block";
      state.src=src
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none";
    };
    return {
      enlarge,
      enlarge2,
      ...toRefs(state),
    };
  },
});
</script>