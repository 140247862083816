<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 商品认领错误怎么办</span>
  </div>
  <div class="text">
    <h3>商品认领错误怎么办</h3>
    <p>
      锵行招商系统用户若认领错商品可以在活动中的报名商品中点击取消认领按钮进行取消商品，取消以后【我的商品】中就不会有该商品，其他的招
      商人员就可以认领该商品了；<br />
      备注：本人或上级账号会有取消认领商品的功能，同时也可以执行批量取消认领/批量认领功能
    </p>
    <img
      src="../../assets/help/goodserror.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge"
    />
  </div>

  <div class="mask" id="mask" @click="enlarge2">
    <img src="../../assets/help/goodserror.png" alt="" style="width: 80%" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const enlarge = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block";
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none";
    };
    return {
      enlarge,
      enlarge2,
    };
  },
});
</script>
