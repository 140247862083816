<template>
  <div class="head">
    <span>常见问题 > 使用问题 > cookie失效解决方法</span>
  </div>
  <div class="text">
    <h3>cookie失效解决方法</h3>
    <p>
      当登录账号后页面提示“cookie已失效，请及时更新”时，代表注册用户的账号cookie失效了，需要去更新，有两种解决方案。
    </p>
    <h5>
      1.手动更新（<span>不建议此种，需要每天都要手动更新，比较麻烦</span>）
    </h5>
    <p>
      登录注册用户，点击到团长账号管理页面，按照页面下方的操作步骤，找到cookie，然后复制到cookie，
    </p>
    <img src="../../assets/help/cookie02.png" alt="" style="width: 800px; cursor: pointer;"
      @click="enlarge(require('../../assets/help/cookie02.png'))" />
    <p>然后把复制的cookie输入到对应的cookie输入框，然后点击保存即可；</p>
    <img src="../../assets/help/cookie03.png" alt="" style="width: 800px; cursor: pointer;"
      @click="enlarge(require('../../assets/help/cookie03.png'))" />
    <h5>2.助手自动更新</h5>
    <p>
      下载助手可每天定时去自动更新助手，不需要去管，只要保证挂助手的电脑不关机即可，详情步骤见菜单“<span>功能介绍-菜单功能-锵行京东招商助手使用方法</span>”。
    </p>
    <p>如果挂了助手还是显示cookie失效，请按步骤逐步排查：</p>
    <p>1.检测挂助手的电脑是否关机；</p>
    <p>2.检查助手是否运行正常，按照步骤重新操作一遍；</p>
  </div>
    <div class="mask" id="mask" @click="enlarge2">
    <img :src="src" alt="" style="width: 80%;margin:5% 0" />
  </div>
</template>
<script>
import { defineComponent, toRefs, reactive } from "vue";

export default defineComponent({
  setup() {
    const state = reactive({
      src: "",
    });
    const enlarge = (src) => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block";
      state.src=src
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none";
    };
    return {
      enlarge,
      enlarge2,
      ...toRefs(state),
    };
  },
});
</script>
<style scoped lang="scss">
.head {
  height: 71px;
  background: rgba(245, 248, 250, 0.39);
  padding-left: 50px;
  span {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 71px;
    color: #8f93a7;
  }
}
.text {
  padding: 50px 50px;
  h3 {
    font-size: 38px;
    font-weight: 400;
    line-height: 38px;
    color: #3e3e3e;
  }
  h5 {
    color: #333;
    margin: 15px 0;
    font-weight: normal;
  }
  p {
    margin:10px 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #3e3e3e;
    width: 837px;
  }
  span {
    color: rgb(249, 150, 59);
    margin: 0;
    display: inline-block;
  }
}
</style>