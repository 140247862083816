<template>
  <div class="head">
    <span>常见问题 > 使用问题 > 团员活动列表中为什么没有活动</span>
  </div>
  <div class="text">
    <h3>团员活动列表中为什么没有活动</h3>
    <p>
      招商团长在锵行招商系统【发布活动】菜单中发布成功活动以后只能团长自己一个人使用该活动，需要点击添加对接人按钮打开添加对接人窗口，选择上对接人以后相应
      的团员就可以查看到相应活动进行招商工作了；
    </p>
    <br />
    <span
      >注意：对接人添加后不可删除，只能新增对接人，请谨慎选择对接人！！！</span
    >
    <img src="../../assets/help/addperson.png" alt="" style="width: 837px;cursor: pointer;" @click="enlarge"/>
  </div>
  <div class="mask" id="mask" @click="enlarge2">
    <img src="../../assets/help/addperson.png" alt="" style="width: 80%" />
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const enlarge = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block";
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none";
    };
    return {
      enlarge,
      enlarge2,
    };
  },
});
</script>
