<template>
  <div class="head" v-if="num == 8">
    <span>常见问题 > 系统介绍 > 招商系统角色及功能 </span>
  </div>
  <div class="head" v-if="num == 9">
    <span>常见问题 > 系统介绍 > 操作流程 </span>
  </div>
  <div class="text" v-if="num == 8">
    <h3>招商系统角色及功能</h3>
    <p>
      锵行招商系统共设计了三种角色，充分实现了在招商的过程中等级划分各司其职的核心价值；主要角色和功能有：
    </p>
    <img
      src="../../assets/help/introduction01.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/introduction01.png'))"
    />
  </div>
  <div class="text" v-if="num == 9">
    <h3>操作流程</h3>
    <p>
      主要讲解锵行招商系统使用前的联盟账号绑定、添加员工子账号的基础设置；主要的使用流程为：注册→绑定联盟账号→设置团长→设置团员→添加
      活动→商家报名→商品审核→业绩统计
    </p>
    <img
      src="../../assets/help/introduction02.png"
      alt=""
      style="width: 837px;"
    />
  </div>

  <div class="mask" id="mask" @click="enlarge2">
    <img :src="src" alt="" style="width: 80%" />
  </div>
</template>
<script>
import { defineComponent, toRefs, reactive, watch } from "vue";
export default defineComponent({
  props: {
    num: { type: Number, require: true },
  },
  setup(props) {
    const state = reactive({
      src: "",
      num: "",
    });

    watch(
      () => props.num,
      (v) => {
        state.num = v;
        console.log(v);
      }
    );
    const enlarge = (src) => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "block";
      state.src = src;
    };
    const enlarge2 = () => {
      console.log("放大图片");
      document.getElementById("mask").style.display = "none";
    };
    return {
      enlarge,
      enlarge2,
      ...toRefs(state),
    };
  },
});
</script>