<template>
  <div class="head">
    <span>常见问题 > 基础设置 > 京东联盟账号绑定 </span>
  </div>

  <div class="text">
    <h3>京东联盟账号绑定</h3>
    <p>
      注册用户登入锵行招商系统，切换为京东联盟，然后进入团长账号管理菜单中进行绑定联盟账号,如下图：填写对应的参数信息,小助手appkey和小助手appsecret不需要填写。
    </p>
    <img
      src="../../assets/help/cookie03.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/cookie03.png'))"
    />
    <h5>AppKey和SecretKey获取方法</h5>
    <p>
      注意：若京东联盟中SecretKey重置后请第一时间在锵行招商系统中更换SecretKey；
    </p>
    <img
      src="../../assets/help/appkey01.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/appkey01.png'))"
    />
    <img
      src="../../assets/help/appkey02.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/appkey02.png'))"
    />
    <img
      src="../../assets/help/appkey03.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/appkey03.png'))"
    />
    <h5>手动获取Cookie获取方法</h5>
    <p>注意：建议使用助手获取Cookie；</p>
     <img
      src="../../assets/help/appkey04.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/appkey04.png'))"
    />
    <img
      src="../../assets/help/appkey05.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/appkey05.png'))"
    />
    <img
      src="../../assets/help/appkey06.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/appkey06.png'))"
    />
    <h5>获取联盟账户ID</h5>
    <img
      src="../../assets/help/appkey07.png"
      alt=""
      style="width: 837px; cursor: pointer"
      @click="enlarge(require('../../assets/help/appkey07.png'))"
    />
  </div>
  <div class="mask" id="mask" @click="enlarge2">
    <img :src="src" alt="" style="width: 80%" />
  </div>
</template>
<script>
import { defineComponent, toRefs, reactive } from "vue";
export default defineComponent({

  setup() {
    const state = reactive({
      src: "",
    });

    const enlarge = (src) => {
      state.src = src;
      document.getElementById("mask").style.display = "block";
      console.log(state.src);
    };
    const enlarge2 = () => {
      document.getElementById("mask").style.display = "none";
      state.src = "";
    };
    return {
      enlarge,
      enlarge2,
      ...toRefs(state),
    };
  },
});
</script>
<style scoped lang="scss">
h5{
  color: #333;
  font-size: 20px;
  margin: 20px 0;
}
 .mask {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      // height: 200px;
      text-align: center;
      background-color: rgba(52, 54, 54, 0.5);
      img {
        margin: 5% 0;
      }
      display: none;
    }
</style>